<template>
  <header class="header">
    
    <div class="header-content max-container">
      <div class="header-content__nav">
        <menu-component />
        <div class="header-content__nav--img">
          <a href="/">
            <img class="header-content__img" src="../assets/images/DublinDaze-Principal.png" alt="wager connection" />
          </a>
        </div>
      </div>
      <div class="header-content__cta">
          <!-- Desktop form -->
          <form class="login__form form__desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
            <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
            <input class="login__form--inputs" type="password" name="password" placeholder="PASSWORD" id="password">
            
            <div class="header-buttons">
              <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
              <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
            </div>
          </form>
      </div>
    </div>
    <div class="max-container">
      <!-- Mobile form -->
      <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
            <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
            <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`" />
            <input class="login__form--inputs" type="text" placeholder="USERNAME" name="account" id="account">
            <input class="login__form--inputs" type="password" placeholder="PASSWORD" name="password" id="password">

            <div class="header-buttons">
              <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
              <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Betslip</a>
            </div>
          </form>
    </div>
    <ModalComponent ref="modal" />
  </header>
</template>

<script>
import ModalComponent from '@/components/modal.vue'
import MenuComponent from '@/components/menu.vue'

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
    MenuComponent
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    }
  },
  setup() {
    const DGS_SITEID = 972;
    const backendUrl = "dublindaze.online";
    return {
      DGS_SITEID,
      backendUrl,
    }
  },
};
</script>
