<template>
  <footer class="footer">
    <div class="footer-content">
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
    </div>
    </footer>
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      return {
        routes: [
          {
            name       :  'sportsbook',
            route      :  'sportsbook'
          },
          {
            name       :  'live betting',
            route      :  'live-betting'
          },
          {
            name       :  '1-855-999-9551',
            route      :  ''
          },
          {
            name       :  'racebook',
            route      :  'racebook'
          },
          {
            name       :  'casino',
            route      :  'casino'
          },
          
          {
            name       :  '1-800 – 3031346',
            route      :  ''
          },
          {
            name       :  'house rules',
            route      :  'house-rules'
          },
          {
            name       :  'sports rules',
            route      :  'sports-rules'
          },
          {
            name       :  'horse-rules',
            route      :  'horse-rules'
          } 
        ]
      }
    },
  };
</script>
